.color-white {
  color: white !important;
}
.color-black {
  color: black !important;
}
.p-2 {
  padding: 2rem;
}
.pt-5 {
  padding-top: 3rem;
}
.pr-30 {
  padding-right: 30px;
}
.p-1 {
  padding: 1rem;
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.d-block {
  display: block;
}
.justify-space-between {
  justify-content: space-between;
}
.d-inline-flex {
  display: inline-flex;
}
.d-inline-block {
  display: inline-block;
}
.d-flex {
  display: flex;
}
.f-right {
  float: right;
}
.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.my-2 {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}
.my-1 {
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
}
.m-10 {
  margin-top: 10px !important;
}
.w-100 {
  width: 100% !important;
}
.b-r-30 {
  border-radius: 30px !important;
}
.m-0 {
  margin: 0;
}
.textbox {
  border-radius: 64px;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.clr-grey {
  color: #797979;
}
.card-details {
  padding: 0.5rem 1rem;
}
.label-section {
  align-items: start;
  display: flex;
}
.card-title {
  font-weight: 600;
  /* padding-left: 2rem; */
}
.icons {
  padding: 1rem 0.2rem;
}
.brand-heading {
  text-align: start;
  border-bottom: 1px solid #d9d9d9;
}
.search-btn {
  background: #ed2a4d !important;
  color: wheat !important;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
.t-align-start {
  text-align: start;
}
.menu-bar {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.12) 48.29%,
      rgba(0, 0, 0, 0.3) 100%
    );
  background-color: transparent !important;
  box-shadow: "none";
  color: "#707070";
  flex-grow: 1;
  width: "100%";
}
.menu-active {
  border-bottom: 2px solid #ed2a4d !important;
  color: #ed2a4d !important;
}

.bnr-default {
  margin-top: -64px;
  width: 100%;
  height: 232px;
  background-size: cover;
  background-position: center;
}
.clr-primary {
  color: var(--primary-color) !important;
}
.cursor-pointer {
  cursor: pointer;
}
.custom-textfield .MuiInputBase-root {
  border-radius: 30px;
}
.ml-30 {
  margin-left: 30px;
}

.profile-avatar-container {
  position: relative;
  display: inline-block;
}

.profile-avatar {
  width: 100px;
  height: 100px;
}

.camera-icon-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  width: 30px;
  height: 30px;
  padding: 0;
  z-index: 1;
}

.camera-icon {
  font-size: 20px;
}

.no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;
}

.react-tel-input .flag-dropdown {
  border-radius: 64px 0 0 64px !important; /* Apply left border radius to country code box */
  border: 1px solid #ced4da; /* Border style */
  border-right: none; /* Remove right border to merge with input */
  padding: 10px !important; /* Adjust padding if needed */
}
.react-tel-input .selected-flag {
  border-radius: 64px 0 0 64px !important;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .card-details {
    padding: 0.5rem;
  }
  .icons {
    padding: 0.5rem 0.1rem;
  }
  .ml-30 {
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .profile-avatar {
    width: 80px;
    height: 80px;
  }
  .camera-icon-button {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 576px) {
  .profile-avatar {
    width: 60px;
    height: 60px;
  }
  .camera-icon-button {
    width: 20px;
    height: 20px;
  }
}
