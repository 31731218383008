/* Footer.css */

.footer {
  background: #ed2a4d;
  padding: 60px 0 20px;
  display: block;
}

.p-lr {
  padding: 0 60px;
}

.wrapper {
  max-width: 2048px;
  margin: 0 auto;
}

.footer-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0 20px;
}

.footer-col {
  flex: 1 1 20%;
  padding: 0 30px 30px 0;
}

.footer-col.np {
  padding: 0;
  height: 1px;
}

.footer-col:last-child {
  padding: 0 0 30px;
}

.footer-logo {
  display: inline-block;
  height: 80px;
  width: auto;
}

.footer-logo img {
  height: 100% !important;
  width: auto;
}

.footer-menu {
  list-style: none;
}

.footer-menu a {
  display: inline-block;
  margin: 0 0 15px;
  text-decoration: none;
  color: #fff !important;
  font-size: 16px;
  transition: opacity 0.28s ease-in-out;
}

.social-row {
  display: flex;
  margin: 20px 0 0;
}

.social-row a {
  margin: 0 14px 0 0;
  transition: transform 0.28s ease-in-out;
  color: white;
}

.social-row a:hover {
  transform: translateY(-2px);
}

.copyright-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.copyright-footer p:first-child {
  margin: 10px 0px 0 0;
}

.copyright-footer p {
  font-size: 12px;
  color: #ccc;
}

.white-text,
.white-text a {
  color: #fff;
  text-decoration: none;
}

.twitter-icon{
  border: 4px solid white;
    border-radius: 50%;
    /* margin: 10px; */
    padding: 11px;
}

/* Responsive styles */

@media (max-width: 1200px) {
  .p-lr {
    padding: 0 30px;
  }
  .icon-size {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 992px) {
  .footer-col {
    flex: 1 1 45%;
    padding: 0 15px 30px 0;
  }
}

@media (max-width: 768px) {
  .footer-col {
    flex: 1 1 100%;
    padding: 0 0 30px 0;
  }

  .copyright-footer {
    flex-direction: column;
    align-items: center;
  }

  .copyright-footer p {
    margin: 5px 0;
    text-align: center;
  }

  .social-row {
    justify-content: center;
  }
}
