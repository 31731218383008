/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input-field div {
  border-radius: 64px;
}

.input-field .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: rgb(0, 4, 15);
  border-width: 2px;
}
.input-field .MuiInputLabel-root.Mui-focused {
  color: rgb(0, 4, 19);
}
.text-field div input {
  margin-left: 10px;
}
.input-field input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px white inset;
  -webkit-text-fill-color: #000;
  border: none;
}

.input-field input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 100px white inset !important;
  -webkit-text-fill-color: #000;
  border: none !important;
}

.input-field input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 100px white inset;
  -webkit-text-fill-color: #000;
  border: none;
}
.input-field input-MuiOutlinedInput-input{
  box-shadow: none;
}

.input[type="text"] {
  border: 0px !important;
  box-shadow: none;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.profileButton {
  background-color: #80808087;
  border-radius: 30px;
  display: flex;
  align-items: center;
  text-transform: none;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  font-weight: 700;
}
